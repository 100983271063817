<template>
  <section class="dtc-main-section">
    <header class="h1-dtc" data-msg1="籍屬" data-msg2="醫學系">
      <div v-for="(item, i) in titles" :key="i">{{ item }}</div>
    </header>
    <main class="dtc-main" v-for="(schoolData, idx) in schools" :key="idx">
      <div>{{ schoolData[0] }}</div>
      <!-- 遍歷每個學校的欄位數據 -->
      <div v-for="(data, index) in schoolData.slice(1)" :key="index">{{ data }}</div>
    </main>
    <footer class="dtc-main">
      <div>總計</div>
      <div>{{ totalData.Aboriginal }}</div>
      <div>{{ totalData.Penghu }}</div>
      <div>{{ totalData.Kinmen }}</div>
      <div>{{ totalData.Lianjiang }}</div>
      <div>{{ totalData.Liuqiu }}</div>
      <div>{{ totalData.Greenisland }}</div>
      <div>{{ totalData.Lanyu }}</div>
      <div>{{ totalData.Rural }}</div>
      <div>{{ totalData.Total }}</div>
    </footer>
  </section>
</template>


<script>
import queryString from "query-string";
const urlPost = "applyform1/Post";
const urlPut = "applyform1/Put";
const urlGet = "applyform1/Get?Identifier=";
const zero = "T00:00:00";


export default {
  props: {
    selectedYear: {
      type: [String, Number],
      required: true
    },
    selectMajornName: {
      type: String,
      required: true
    }
  },
  name: "totalSpend5",
  data() {
    return {
      titles: [
        "",
        "原住民籍",
        "澎湖縣",
        "金門縣",
        "連江縣",
        "綠島籍",
        "琉球籍",
        "蘭嶼籍",
        "偏鄉籍",
        "總計",
      ],
      schools: [],
      totalData: {
        Aboriginal: 0,
        Penghu: 0,
        Kinmen: 0,
        Lianjiang: 0,
        Liuqiu: 0,
        Greenisland: 0,
        Lanyu: 0,
        Rural: 0,
        Total: 0,
      },
    };
  },
  watch: {
    selectedYear(newYear) {
      this.getData(newYear, this.selectMajornName); // 年度变化时调用 getData
    },
    selectMajornName(newMajorName) {
      this.getData(this.selectedYear, newMajorName); // 学系名称变化时调用 getData
    }
  },
  methods: {
    doctorRowUpdate(item) {
      // 這裡可以加入更新邏輯
    },

    async getData() {
      try {
        console.log(this.selectMajornName);
          const response = await axios.get(`assignnumber/Get`, {
            params: { selectedYear: this.selectedYear, selectMajornName: this.selectMajornName }
          });
          const data = response.Items;
          this.schools = data.map(school => [
            school.SchoolName, // 學校
            school.Aboriginal, // 原住民籍
            school.Penghu,     // 澎湖縣
            school.Kinmen,     // 金門縣
            school.Lianjiang,  // 連江縣
            school.Liuqiu,     // 琉球籍
            school.Greenisland,// 綠島籍
            school.Lanyu,      // 蘭嶼籍
            school.Rural,      // 偏鄉籍
            school.Total       // 總計
          ]);

          // 計算總計
          this.totalData = {
            Aboriginal: data.reduce((sum, school) => sum + school.Aboriginal, 0),
            Penghu: data.reduce((sum, school) => sum + school.Penghu, 0),
            Kinmen: data.reduce((sum, school) => sum + school.Kinmen, 0),
            Lianjiang: data.reduce((sum, school) => sum + school.Lianjiang, 0),
            Liuqiu: data.reduce((sum, school) => sum + school.Liuqiu, 0),
            Greenisland: data.reduce((sum, school) => sum + school.Greenisland, 0),
            Lanyu: data.reduce((sum, school) => sum + school.Lanyu, 0),
            Rural: data.reduce((sum, school) => sum + school.Rural, 0),
            Total: data.reduce((sum, school) => sum + school.Total, 0),
          };
          //console.log(this.assignNumberData);
      } catch (error) {
          console.error('There was a problem with the axios operation:', error);
      }
    },
  },
  async mounted() {
    // 當組件掛載完成後，您可以進行其他操作
    await this.getData();
  },
  beforeMount() {
    // 在組件掛載之前，您可以進行初始化
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  // width: 1280px;
  // display: block;
  // margin: 0 auto;
  // text-align: center;
}
.h1-dtc,
.dtc-main {
  display: grid;
  position: relative;
  grid-template-columns: 1fr repeat(9, 120px);
  border: 1px solid black;
  // background: var(--gray);
  > div {
    position: relative;
    height: 60px;
    font-weight: bold;
    line-height: 60px;
    font-size: 1.3rem;
    border-right: 1px solid black;
    //color: white;
  }
  > div:last-child {
    border-right: none;
  }
  > div:first-child::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    display: block;
    width: 100%;
    height: 1px;
    background: black;
    transform: rotate(13deg);
  }
}
.h1-dtc::after,
.h1-dtc::before {
  content: attr(data-msg1);
  position: absolute;
  font-size: 16px;
  top: 5px;
  left: 140px;
}

.h1-dtc::after {
  content: attr(data-msg2);
  top: 30px;
  left: 10px;
}
.dtc-main {
  border-top: none;
  > div {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }
  > div:first-child::after {
    opacity: 0;
  }
}
</style>
